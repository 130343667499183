<template>
  <div class="sidebar">
    <div class="tabs">
      <Focusable class="tab" :focus="!toggleTab" @click="toggleTab = false">
        数据库
      </Focusable>
      <Focusable class="tab" :focus="toggleTab" @click="toggleTab = true">
        视图
      </Focusable>
    </div>
    <TabDatasheet v-if="toggleTab" />
    <TabDestination v-else />
  </div>
</template>

<script>
import Focusable from "@/views/datamodel/components/focusable";
import TabDestination from "./TabDestination";
import TabDatasheet from "./TabDatasheet";

export default {
  components: { Focusable, TabDestination, TabDatasheet },
  data() {
    return {
      toggleTab: false
    };
  }
};
</script>

<style lang="less" scoped>
.sidebar {
  display: flex;
  flex-direction: column;
  position: relative;
  .tabs {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    padding: 8px;
  }
  .focusable {
    width: 80px;
    text-align: center;
    padding: 0 14px;
    font-size: 13px;
    border-radius: 28px;
  }
  .tab-content {
    flex: 1 1 0;
    overflow: auto;
  }
}
</style>
