<template>
  <VirtualList
    placeholder="查找表"
    :loading="loading"
    :list="tables"
    :filter-keys="['tableAlias', 'tableName']"
    :extra-props="{ getItemTitle, itemIcon, itemLabel }"
    @select="onSelect"
  />
</template>

<script>
import VirtualList from "@/views/datamodel/components/virtual-list";
import * as schema from "@/views/datamodel/utils/resolve-schema";

export default {
  components: { VirtualList },
  props: {
    destinationId: String
  },
  data() {
    return {
      loading: true,
      tables: []
    };
  },
  created() {
    this.getTables();
  },
  watch: {
    destinationId() {
      this.getTables();
    }
  },
  methods: {
    async getTables() {
      try {
        this.loading = true;
        const destinationId = this.destinationId;
        const data = await schema.resolveTables(destinationId);
        // 请求响应可能太久，期间用户可能已经切换目标库
        // 通过闭包特性来保证响应时的目标库和发送请求时的目标库一致
        if (destinationId === this.destinationId) {
          this.tables = data;
        }
      } catch (err) {
        this.tables = [];
      } finally {
        this.loading = false;
      }
    },
    onSelect(item) {
      this.$emit("select", item);
    },
    getItemTitle(item) {
      return `表名：${item.tableName}\n别名：${item.tableAlias}`;
    },
    // eslint-disable-next-line no-unused-vars
    itemIcon(h) {
      return <i class="ndl-icon-layers"></i>;
    },
    itemLabel(h, item) {
      return item.tableAlias || item.tableName;
    }
  }
};
</script>
