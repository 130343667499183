<template>
  <div class="page-wrapper">
    <div class="main-content">
      <Header />
      <Tabs focus-on-add-button />
      <Guide class="body" />
    </div>
    <component v-resizable:left :is="activePane" class="side-content" />
  </div>
</template>

<script>
import Workspace from "./Workspace";
import Guide from "./components/guide";
import workspace from "./models/workspace";

export default {
  mixins: [Workspace],
  components: { Guide },
  created() {
    workspace.activePane = null;
  }
};
</script>
