<template>
  <div class="tab-content">
    <header v-if="destinationId">
      <Focusable @click="destinationId = null">
        <i class="ndl-icon-arrow-left"></i>
      </Focusable>
      <span class="ndl-ellipsis">
        {{ destinationName }}
      </span>
    </header>
    <Tables
      v-if="destinationId"
      class="content"
      :destination-id="destinationId"
      @select="onSelectTable"
    />
    <Destinations
      v-else
      class="content"
      :auto-select="autoSelect"
      @select="onSelectDestination"
    />
  </div>
</template>

<script>
import { createDatasheet } from "@/api/datasheet";
import workspace, { ModelTree } from "@/views/datamodel/models/workspace";
import connectorList from "@/views/connections/models/connectors";
import Focusable from "@/views/datamodel/components/focusable";
import Destinations from "./Destinations";
import Tables from "./DestinationTables";

export default {
  components: { Focusable, Destinations, Tables },
  data() {
    return {
      destinationId: null,
      autoSelect: true
    };
  },
  computed: {
    destinationName() {
      return connectorList.getConnectorName(this.destinationId);
    }
  },
  methods: {
    onSelectDestination(destination) {
      this.destinationId = destination.id;
      this.autoSelect = false;
    },
    async onSelectTable(table) {
      const parentId = this.$route.params.workspaceId;
      const { data } = await createDatasheet({
        parentId,
        nodeType: ModelTree.NODE_DATASHEET,
        datasetId: table.id,
        datasetType: "table"
      });
      workspace.currentWorkspace.addChild(data);
      this.$router.replace({
        name: "datamodel-workspace",
        params: {
          workspaceId: parentId,
          datasheetId: data.nodeId
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.tab-content {
  display: flex;
  flex-direction: column;
  header {
    font-weight: bold;
    flex-shrink: 0;
    padding: 12px;
    display: flex;
    align-items: center;
    .focusable {
      flex-shrink: 0;
      margin-right: 6px;
    }
  }
  .content {
    flex: 1 1 0;
    height: 0;
    overflow: auto;
    padding: 6px;
  }

  /deep/ .list-wrapper {
    & div[role="header"],
    & div[role="group"],
    & div[role="footer"] {
      max-width: 100% !important;
    }
  }
}
</style>
