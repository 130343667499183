<template>
  <div class="guide">
    <a-empty style="flex: 1 1 0">
      <span slot="description">
        <a @click="showSideBar = !showSideBar">选择数据源</a>
        <span> 以创建新的视图</span>
      </span>
    </a-empty>
    <transition name="ndl-slide-right">
      <Sidebar v-resizable:left v-if="showSideBar" />
    </transition>
  </div>
</template>

<script>
import { resizable } from "@/utils/directives";
import Sidebar from "./Sidebar";
import * as schema from "@/views/datamodel/utils/resolve-schema";

export default {
  components: { Sidebar },
  directives: { resizable },
  data() {
    return { showSideBar: true };
  },
  beforeCreate() {
    schema.resolveTables.cache.clear();
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/datamodel/assets/vars.less";

.guide {
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 @workspace-edge;
}
.sidebar {
  width: 300px;
  min-width: 300px;
  max-width: 70%;
  height: 100%;
  flex-shrink: 0;
  border-left: solid 1px #ebebeb;
}
</style>
