<template>
  <div class="tab-content">
    <Tree
      v-for="item in workspaceList"
      readonly
      :key="item.nodeId"
      :node="item"
      :add-class="addClass"
      @select="onSelect"
    />
  </div>
</template>

<script>
import { createDatasheet } from "@/api/datasheet";
import { Workspace, ModelTree } from "@/views/datamodel/models/workspace";
import workspace from "@/views/datamodel/models/workspace";
import Tree from "@/views/datamodel/components/tree";

export default {
  components: { Tree },
  data() {
    const cloneWorkspace = new Workspace(workspace);
    return {
      workspaceList: cloneWorkspace.children
    };
  },
  methods: {
    /**
     * @param {Workspace} node
     */
    addClass(node) {
      return { disabled: !(node.isFolder || node.isDatasheet) };
    },
    /**
     * @param {Workspace} node
     */
    async onSelect(node) {
      if (node.isFolder) {
        node.toggleExpand();
      }
      if (!node.isDatasheet) {
        return;
      }
      const parentId = this.$route.params.workspaceId;
      const { data } = await createDatasheet({
        parentId,
        nodeType: ModelTree.NODE_DATASHEET,
        datasetId: node.nodeId,
        datasetType: "view"
      });
      workspace.currentWorkspace.addChild(data);
      this.$router.replace({
        name: "datamodel-workspace",
        params: {
          workspaceId: parentId,
          datasheetId: data.nodeId
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.tab-content {
  padding: 6px;
  /deep/ .tree-node {
    border-radius: 4px;
  }
  /deep/ .tree-node.disabled {
    opacity: 0.35;
    cursor: not-allowed;
  }
}
</style>
