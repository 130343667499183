<template>
  <a-skeleton v-if="loading" active />
  <a-empty v-else-if="empty" />
  <div v-else>
    <ConnectorCard
      v-for="item in destinations"
      :key="item.id"
      :connector-id="item.id"
      :show-category="false"
      @click.native="$emit('select', item)"
    />
  </div>
</template>

<script>
import connectorList from "@/views/connections/models/connectors";
import ConnectorCard from "@/views/connections/components/connector-card";

export default {
  components: { ConnectorCard },
  props: {
    autoSelect: Boolean
  },
  computed: {
    loading() {
      return connectorList.loading;
    },
    destinations() {
      return connectorList.destinations.filter(item => item.supportQuery);
    },
    empty() {
      return !this.loading && !this.destinations.length;
    }
  },
  async created() {
    // 等待连接器加载完成，只有一个 JDBC 目标库时，自动选中
    await connectorList.init();
    const destinations = this.destinations;
    if (destinations.length === 1 && this.autoSelect) {
      this.$emit("select", destinations[0]);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.card {
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
  padding: 12px;
  &:hover {
    background: rgba(55, 53, 47, 0.08);
  }
  /deep/ img {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
  /deep/ .content {
    padding: 0;
  }
  /deep/ .title {
    font-size: 14px;
  }
  /deep/ .desc {
    font-size: 12px;
  }
}
</style>
